export function useAuth() {
  const getAuthToken = (): string => {
    let token = window.Telegram.WebApp.initData;

    if (token.includes('user')) {
      localStorage.setItem('token', token);
    } else {
      token = localStorage.getItem('token') ?? '';
    }
    return `tma ${token}`;
  };
  return {
    getAuthToken
  };
}
