import { $axios, Response } from '@/plugins/axios';
import { ProfileStats, TgUserInfo, UserInfo, UserRanking } from '@/interfaces/user.interfaces';
import { PointBalance } from '@/interfaces/round.interfaces';

export async function saveUserRequest(tgUserData: TgUserInfo): Promise<Response<UserInfo>> {
  return (await $axios.post<Response<UserInfo>>('/users', tgUserData)).data;
}

export async function updateUserRequest(tgUserData: TgUserInfo): Promise<Response<UserInfo>> {
  return (await $axios.patch<Response<UserInfo>>(`/users/me`, tgUserData)).data;
}

export async function signTermsConditionsRequest(): Promise<Response<UserInfo>> {
  return (await $axios.post<Response<UserInfo>>(`/users/terms-and-conditions/sign`)).data;
}

export async function saveUserWalletRequest(address: string): Promise<Response<boolean>> {
  return (await $axios.post<Response<boolean>>(`/users/wallet`, { address })).data;
}

export async function getUserPointBalance(): Promise<Response<PointBalance>> {
  return (await $axios.get<Response<PointBalance>>(`/users/point-balance`)).data;
}

export async function getUserRankingHistory(): Promise<Response<UserRanking>> {
  return (await $axios.get<Response<UserRanking>>(`/users/ranking`)).data;
}

export async function getUserStatsRequest(pageSize: number): Promise<Response<ProfileStats>> {
  return (await $axios.get<Response<ProfileStats>>(`/users/me/stats?page=1&size=${pageSize}`)).data;
}

export async function getUserReferralLinkRequest(): Promise<Response<{ url: string }>> {
  return (await $axios.get<Response<{ url: string }>>(`/users/referral-link`)).data;
}

export async function getUserInvitesRequest(): Promise<Response<{ countOfInvites: number }>> {
  return (await $axios.get<Response<{ countOfInvites: number }>>(`/users/invites`)).data;
}

export async function getUserReusableReferralLinkRequest(): Promise<Response<{ url: string }>> {
  return (await $axios.get<Response<{ url: string }>>(`/users/reusable-referral-link`)).data;
}
