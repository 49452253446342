export function truncate(string: string, maxLength: number): string {
  if (string.length <= maxLength) {
    return string;
  }

  const halfLength = Math.floor((maxLength - 3) / 2);
  const start = string.slice(0, halfLength);
  const end = string.slice(string.length - halfLength);

  return `${start}...${end}`;
}

export function isStringInExceptions(inputString: string, exceptionArray: string[]): boolean {
  return exceptionArray.some((el) => inputString.includes(el));
}

export function isImageUrlValid(url: string): boolean {
  if (!url) return false;
  else {
    const pattern = new RegExp('^https?:\\/\\/.+\\.(png|jpg|jpeg|bmp|gif|webp)$', 'i');
    return pattern.test(url);
  }
}

export function formatDecimals(number: number, decimals = 2): number {
  const decimalPlaces = decimals > 0 ? `{0,${decimals}}` : `{0}`;
  const regexPattern = new RegExp(`^-?\\d+(?:\\.\\d${decimalPlaces})?`);

  const withDecimals = number.toString().match(regexPattern)?.[0] ?? '';
  return Number.parseFloat(withDecimals);
}

export function getTimeDifferenceInSeconds(date1: Date, date2: Date): number {
  const differenceInMs = date2.getTime() - date1.getTime();
  return formatDecimals(differenceInMs / 1000, 0);
}

export function formatNumberWithCommas(number: number): string {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatNumberWithSpaces(number: number): string {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function isMobile() {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}
