import { Ref, ref } from 'vue';
import { BetStat, TgUserInfo, UserHook, UserInfo, UsersLeaderBoard } from '@/interfaces/user.interfaces';
import {
  getUserInvitesRequest,
  getUserPointBalance,
  getUserRankingHistory,
  getUserReferralLinkRequest,
  getUserStatsRequest,
  saveUserWalletRequest,
  signTermsConditionsRequest,
  updateUserRequest
} from '@/api/user.api';
import { useDateFormat } from '@vueuse/core';
import { formatDecimals } from '@/utils/utils';

const UPDATE_USER_POINT_BALANCE_TIME = 1000 * 30;
let updateUserPointsBalanceInterval: ReturnType<typeof setInterval>;

const userData = ref<UserInfo>({} as UserInfo);
const userPointsBalance = ref<number>(0);
const userPlace = ref(0);
const dailyTradesCount = ref(0);
const leaderBoard: Ref<UsersLeaderBoard[]> = ref([]);

const userTotalWinRatio = ref(0);
const totalWinPaid = ref(0);
const tradingVolume = ref(0);

const betsStats: Ref<BetStat[]> = ref([]);
const userReferralLink = ref<string>('');
const userInvites = ref<number>(0);

export function useUserData(): UserHook {
  initUserPointBalance();
  turnOnWalletBalanceUpdate();

  return {
    userData,
    userPointsBalance,
    initUserData,
    acceptUserTerms,
    saveUserWallet,
    userPlace,
    dailyTradesCount,
    leaderBoard,
    userTotalWinRatio,
    totalWinPaid,
    tradingVolume,
    betsStats,
    userReferralLink,
    initUserReferralLink,
    userInvites,
    initUserInvites,
    initUserPointBalance,
    initUserStatsData,
    initUserRankingData
  };
}

function turnOnWalletBalanceUpdate() {
  turnOffWalletBalanceUpdate();
  updateUserPointsBalanceInterval = setInterval(() => {
    initUserPointBalance();
  }, UPDATE_USER_POINT_BALANCE_TIME);
}

function turnOffWalletBalanceUpdate() {
  clearInterval(updateUserPointsBalanceInterval);
}

async function initUserInvites() {
  try {
    const userRankingResponse = await getUserInvitesRequest();
    if (userRankingResponse.success && userRankingResponse.data) {
      userInvites.value = userRankingResponse.data.countOfInvites;
    }
  } catch (error) {
    console.log('Error while initUserReferralLink:', error);
  }
}

async function initUserReferralLink() {
  try {
    const userRankingResponse = await getUserReferralLinkRequest();
    if (userRankingResponse.success && userRankingResponse.data) {
      userReferralLink.value = userRankingResponse.data.url;
    }
  } catch (error) {
    console.log('Error while initUserReferralLink:', error);
  }
}

async function initUserStatsData(pageSize: number) {
  try {
    const userRankingResponse = await getUserStatsRequest(pageSize);
    if (userRankingResponse.success && userRankingResponse.data) {
      userTotalWinRatio.value = userRankingResponse.data.winRatio;
      totalWinPaid.value = userRankingResponse.data.totalWinPaid;
      tradingVolume.value = userRankingResponse.data.tradingVolume;

      betsStats.value = userRankingResponse.data.bets.map((el) => {
        el.placedOn = useDateFormat(el.placedOn, 'D.MM.YY, HH:mm').value;
        return el;
      });
    }

    return {
      totalRecords: userRankingResponse.data?.pagination.totalResults ?? 0
    };
  } catch (error) {
    console.log('Error while initUserStatsData:', error);
    return {
      totalRecords: 0
    };
  }
}

async function initUserRankingData() {
  try {
    const userRankingResponse = await getUserRankingHistory();
    if (userRankingResponse.success && userRankingResponse.data) {
      userPlace.value = userRankingResponse.data.userPlace;
      dailyTradesCount.value = userRankingResponse.data.dailyTradesCount;
      leaderBoard.value = userRankingResponse.data.users.map((el, index) => {
        return Object.assign(el, { place: index + 1 });
      });
    }
  } catch (error) {
    console.log('Error while initUserPointBalance:', error);
  }
}

async function initUserPointBalance() {
  try {
    const userPointResponse = await getUserPointBalance();
    if (userPointResponse.success && userPointResponse.data) {
      userPointsBalance.value = formatDecimals(userPointResponse.data.pointBalance);
    }
  } catch (error) {
    console.log('Error while initUserPointBalance:', error);
  }
}

async function saveUserWallet(walletAddress: string) {
  try {
    const result = await saveUserWalletRequest(walletAddress);
    console.log('saveUserWallet result:', result);
  } catch (error) {
    console.log('Error while saving user wallet:', error);
  }
}

async function acceptUserTerms() {
  try {
    const signTermRes = await signTermsConditionsRequest();
    console.log('signTermRes:', signTermRes);
  } catch (error) {
    console.log('Error while signTermsConditionsRequest:', error);
  }
}

async function initUserData() {
  try {
    const saveUserRes = await updateUserRequest(getTgUserData());
    if (saveUserRes.success && saveUserRes.data) {
      userData.value = saveUserRes.data;
    }
  } catch (error) {
    console.log('Error while saveUserRequest:', error);
  }
}

function getTgUserData(): TgUserInfo {
  try {
    let webAppUser = window.Telegram.WebApp.initDataUnsafe.user;
    console.log('webAppUser', webAppUser);
    if (webAppUser) {
      saveTgDataToLocalStorage(webAppUser);
    } else {
      webAppUser = getTgDataFromLocalStorage();
    }

    return {
      telegramId: webAppUser.id,
      firstName: webAppUser.first_name,
      lastName: webAppUser.last_name,
      username: webAppUser.username,
      languageCode: webAppUser.language_code,
      addedToAttachmentMenu: webAppUser.added_to_attachment_menu,
      allowsWriteToPm: webAppUser.allows_write_to_pm
    };
  } catch (error) {
    console.log('Error while getting telegram user data:', error);
    return {} as TgUserInfo;
  }
}

function saveTgDataToLocalStorage(tgData: WebAppUser) {
  localStorage.setItem('tgData', JSON.stringify(tgData));
}

function getTgDataFromLocalStorage(): WebAppUser {
  const webAppUser: WebAppUser = JSON.parse(localStorage.getItem('tgData') ?? '');
  return webAppUser ?? ({} as WebAppUser);
}
