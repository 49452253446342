import axios from 'axios';
import { isStringInExceptions } from '@/utils/utils';
import { toast } from 'vue3-toastify';
import { useAuth } from '@/hooks/auth.hook';

export interface Response<T> {
  success: boolean;
  data?: T;
  error?: ResponseError;
}

export interface ResponseError {
  type: string;
  description: string;
}

export interface Pagination {
  totalPages: number;
  currentPage: number;
  totalResults: number;
}

const nonAuthDomains = ['https://api.binance.com'];

const { getAuthToken } = useAuth();

console.log('process.env.VUE_APP_BASE_URL', process.env.VUE_APP_BASE_URL);
export const $axios = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

$axios.interceptors.request.use(
  (config) => {
    if (!isStringInExceptions(config.url ?? '', nonAuthDomains)) {
      config.headers.set('Authorization', getAuthToken());
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

$axios.interceptors.response.use(
  (response) => {
    if (response?.data && !response.data?.success && !isStringInExceptions(response.config.url ?? '', nonAuthDomains)) {
      toast.error(response?.data?.error?.description);
    }

    return response;
  },
  (error) => {
    toast.error(error.message);
    return Promise.reject(error);
  }
);
