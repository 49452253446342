import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { Buffer } from 'buffer';
import Vue3Toasity, { type ToastContainerOptions } from 'vue3-toastify';
import { createPinia } from 'pinia';
import VueClipboard from 'vue3-clipboard'

globalThis.Buffer = Buffer;

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);

app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})

app.use(Vue3Toasity, {
  theme: 'colored',
  position: 'top-center',
  autoClose: 2000,
  hideProgressBar: true
} as ToastContainerOptions);
app.mount('#app');
