export enum ROUTES {
  MENU = 'menu',
  RANKING = 'ranking',
  TRADE = 'trade',
  AFFILIATE = 'affiliate',
  PROFILE = 'profile',
  TERMS = 'terms',
  AIR_DROP = 'air-drop',
  QUESTS = 'quests',
  PRE_RELEASE = 'pre-release',
  CLAIM_HISTORY = 'claim-history',
  TUTORIALS = 'tutorials',
  TUTORIALS_ITEM = 'tutorials_item',
}
