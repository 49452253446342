<script setup lang="ts">
import NavigationMenu from '@/components/NavigationMenu.vue';
import { onMounted, ref, watch } from 'vue';
import { useUserData } from '@/hooks/user.hook';
import { ROUTES } from '@/enums/router.enums';
import { useRoute } from 'vue-router';
import router from '@/router';

const { initUserData, userData } = useUserData();

const showBottomMenu = ref(false);

onMounted(async () => {
  window.Telegram.WebApp.expand();
  window.Telegram.WebApp.BackButton.isVisible = true;
  window.Telegram.WebApp.BackButton.onClick(() => {
    //TODO: implement better logic
    window.history.back();
  });
  await initUserData();

  if (!userData.value.signedTermsAndConditions && window.location.pathname !== '/pre-release') {
    router.push({ name: ROUTES.TERMS });
  }
});

const route = useRoute();
watch(
  () => route.fullPath,
  () => {
    showBottomMenu.value = !(route.name === ROUTES.TERMS || route.name === ROUTES.PRE_RELEASE);
  }
);
</script>

<template>
  <div class="relative container max-w-xl h-full">
    <div class="content">
      <router-view />
    </div>
    <navigation-menu v-if="showBottomMenu" />
  </div>
</template>

<style lang="scss">
@import '@/style.scss';
</style>
