import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { ROUTES } from '@/enums/router.enums';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: { name: ROUTES.TRADE }
  },
  {
    path: '/menu',
    name: ROUTES.MENU,
    component: () => import('../views/MenuView.vue')
  },
  {
    path: '/ranking',
    name: ROUTES.RANKING,
    component: () => import('../views/RankingView.vue')
  },
  {
    path: '/trade',
    name: ROUTES.TRADE,
    component: () => import('../views/TradeView.vue')
  },
  {
    path: '/affiliate',
    name: ROUTES.AFFILIATE,
    component: () => import('../views/AffiliateView.vue')
  },
  {
    path: '/air-drop',
    name: ROUTES.AIR_DROP,
    component: () => import('../views/AirDropView.vue')
  },
  {
    path: '/profile',
    name: ROUTES.PROFILE,
    component: () => import('../views/ProfileView.vue')
  },
  {
    path: '/terms',
    name: ROUTES.TERMS,
    component: () => import('../views/TermsView.vue')
  },
  {
    path: '/quests',
    name: ROUTES.QUESTS,
    component: () => import('../views/QuestsView.vue')
  },
  {
    path: '/pre-release',
    name: ROUTES.PRE_RELEASE,
    component: () => import('../views/PreReleaseView.vue')
  },
  {
    path: '/claim-history',
    name: ROUTES.CLAIM_HISTORY,
    component: () => import('../views/ClaimHistoryView.vue')
  },
  {
    path: '/tutorials',
    name: ROUTES.TUTORIALS,
    component: () => import('../views/TutorialsView.vue')
  },
  {
    path: '/tutorials-item',
    name: ROUTES.TUTORIALS_ITEM,
    component: () => import('../views/TutorialsItemView.vue')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
