import { ROUTES } from '@/enums/router.enums';
import { MenuList, NavigationMenu } from '@/interfaces/navigation.interfaces';
import { ref } from 'vue';

export const navigationMenu: NavigationMenu[] = [
  {
    title: 'Menu',
    routes: ROUTES.MENU,
    icon: 'icon-menu'
  },
  {
    title: 'Ranking',
    routes: ROUTES.RANKING,
    icon: 'icon-ranking'
  },
  {
    title: 'Trade',
    routes: ROUTES.TRADE,
    icon: 'icon-trade'
  },
  {
    title: 'Affiliate',
    routes: ROUTES.AFFILIATE,
    icon: 'icon-affiliate'
  },
  {
    title: 'Airdrop',
    routes: ROUTES.AIR_DROP,
    icon: 'icon-airdrop'
  }
];

export const menuItems: MenuList[] = [
  {
    icon: 'icon-quests',
    title: 'Quests',
    routes: ROUTES.QUESTS,
    optionalLabel: ref(5), // Total quest count
    isNew: ref(false)
  },
  {
    icon: 'icon-cup',
    title: 'Ranking',
    routes: ROUTES.RANKING,
    optionalLabel: ref(0),
    isNew: ref(false)
  },
  {
    icon: 'icon-affiliate text-[10px]',
    title: 'Affiliate',
    routes: ROUTES.AFFILIATE,
    isNew: ref(false)
  },
  {
    icon: 'icon-airdrop',
    title: 'Airdrop',
    routes: ROUTES.AIR_DROP,
    isNew: ref(false)
  },
  {
    icon: 'icon-profile',
    title: 'Profile',
    routes: ROUTES.PROFILE,
    isNew: ref(false)
  },
  {
    //TODO: remove after test
    icon: 'icon-quests',
    title: 'Pre release (TEST)',
    routes: ROUTES.PRE_RELEASE,
    isNew: ref(false)
  }
];

export const helpMenuItems: MenuList[] = [
  {
    icon: 'icon-info',
    title: 'About us',
    routes: ROUTES.MENU,
    isNew: ref(false)
  },
  {
    icon: 'icon-support',
    title: 'Support',
    routes: ROUTES.MENU,
    isNew: ref(false)
  },
  {
    icon: 'icon-tutorials',
    title: 'Tutorials',
    routes: ROUTES.TUTORIALS,
    isNew: ref(false)
  }
];
